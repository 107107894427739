<template>
    <b-overlay :show='showLoader'>
        <b-card>
            <b-row v-if='paper'>
                <b-col cols='8'>
                    <template> <!-- only school can submit paper. In next version, admin will be able to edit/delete paper -->
                        <b-button @click="$router.push({name: 'admin-edit-paper', params: { id: id}})" variant="warning">{{$t('general.edit')}}</b-button>
                        <b-button @click="onDeleteButtonPressed" class="ml-1" variant="danger">{{ $t('general.delete') }}</b-button>
                    </template>

                    <template>
                        <b-button v-if='paper.StateID === 1' @click="submitModal = true; paperId = paper.ID" class="ml-1" variant="info">{{ $t('general.submit_paper') }}</b-button>
                    </template>
                </b-col>
                <b-col class="text-right">
                    <b-button @click="$router.go(-1)" variant='secondary'>{{ $t('general.go_back') }}</b-button>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <div v-if='paper'>
                <b-row>
                    <b-col  class="text-sm-left text-center" cols='12' sm='8'>
                        <h5>{{ $t('general.title') }}:</h5>
                        <h4>{{ paper.Title }}</h4>
                    </b-col>
                    <b-col class="text-sm-right text-center" cols='12' sm='4'>
                        <h3><h5>{{ $t('general.school_year') }}: </h5>{{ paper.SchoolYear.Name }}</h3>
                    </b-col>
                </b-row>
                <hr>
                <b-row class="mt-1">
                    <b-col>
                        <h4>{{ $t('general.authors') }}</h4>
                        <p class="summary">{{ joinNames(paper.Authors) }}</p>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col>
                        <h4>{{ $t('general.menthors') }}</h4>
                        <p class="summary">{{ getFullnameFromMentor(paper.Mentors) }}</p>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col>
                        <h4>{{ $t('general.type') }}</h4>
                        <template v-if='paper.TypeID === 1'>
                            <p class="summary">{{ $t('general.type_raziskovalna') }}</p>
                        </template>
                        <template v-else-if='paper.TypeID === 2'>
                            <p class="summary">{{ $t('general.type_inovative') }}</p>
                        </template>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col>
                        <h4>{{ $t('general.status') }}</h4>
                        <template v-if='paper.StateID === 1'>
                            <p class="summary">{{ $t('general.status_registered') }}</p>
                        </template>
                        <template v-else-if='paper.StateID === 2'>
                            <p class="summary">{{ $t('general.status_submited') }}</p>
                        </template>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col>
                        <template v-if='paper.Fields && paper.Fields.length !== 0'>
                            <h4>{{ $t('general.areas') }}</h4>
                            <p class="summary">{{ getPodrocjeNazivFromArray(paper.Fields) }}</p>
                        </template>
                        <template v-else>
                            <h4>{{ $t('general.area') }}</h4>
                            <p>/</p>
                        </template>
                    </b-col>
                </b-row>
                <b-row class="mt-1" v-if='paper.Place'>
                    <b-col>
                        <h4>{{ $t('general.reached_place') }}</h4>
                        <p class="summary">{{ paper.Place }}</p>
                    </b-col>
                </b-row>
                <b-row class="mt-1" v-if='paper.Award'>
                    <b-col>
                        <h4>{{ $t('general.award') }}</h4>
                        <p class="summary">{{ paper.Award }}</p>
                    </b-col>
                </b-row>
                <b-row class="mt-1" v-if='paper.Points'>
                    <b-col>
                        <h4>{{ $t('general.number_of_points') }}</h4>
                        <p class="summary">{{ paper.Points }}</p>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col>
                        <h4>{{ $t('general.summary') }}</h4>
                        <p class="summary">{{ paper.Summary }}</p>
                    </b-col>
                </b-row>
                <b-row class="mt-1" v-if='paper.HasFile === 1'>
                    <b-col>
                        <h4>{{ $t('general.file') }}</h4>
                        <a class="text-primary" @click="downloadItem">Preglej datoteko</a>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-modal id="confirm-deletion-modal" :cancel-disabled="true" hide-footer>
            <div class="d-block text-center">
                <p>{{ $t('general.delete_paper_confirmation') }}</p>
                <div>
                    <b-button variant="warning" @click="onRejectDeletion">{{ $t('general.no') }}</b-button>
                    <b-button variant="danger" class="ml-2" @click="onAcceptDeletion">{{ $t('general.yes') }}</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal :title="$t('general.submit_paper')" v-model="submitModal" centered hide-footer>
            <template #default>
                <b-row class='mt-1'>
                    <label for='file'>{{ $t('general.file') }}</label>
                    <b-form-file id="file" v-model="file" placeholder="Poišči datoteko ali jo povleci noter..." browse-text="Poišči"></b-form-file>
                </b-row>
                <b-button class="mt-1 float-right" type="submit" variant="primary" @click="onSubmitPaper">{{ $t('general.submit') }}</b-button>
            </template>
        </b-modal>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BButton, BModal, BFormFile} from 'bootstrap-vue'

    export default {
        components: {
            BCol,
            BRow,
            BOverlay,
            BCard,
            BButton,
            BModal,
            BFormFile
        },
        data() {
            return {
                showLoader: false,
                paper: null,
                id: 0,
                submitModal: false,
                paperId: 0,
                file: null
            }
        },
        methods: {
            downloadItem() {
                const thisIns = this
                const url = `/api/v1/admin/naloga/${thisIns.id}/pdf`
                window.open(url, '_blank')
            },
            onSubmitPaper() {
                const thisIns = this
                thisIns.submitModal = false
                thisIns.showLoader = true
                if (thisIns.file === null) {
                    thisIns.$http.post(`/v1/admin/naloga/${thisIns.id}/oddajBrez`)
                        .then(() => {
                            thisIns.$printSuccess(`${thisIns.$t('general.paper_submited_successfully')}\n`)
                            setTimeout(() => {
                                this.$router.go(-1)
                            }, 500)

                            thisIns.showLoader = false

                        })
                        .catch(error => {
                            thisIns.$printError(`${thisIns.$t('general.paper_submit_error')}\n${error.message}`)
                            thisIns.showLoader = false
                        })
                } else {
                    const formData = new FormData()
                    formData.append('file', thisIns.file)
                    thisIns.$http.post(`/v1/admin/naloga/${thisIns.id}/oddaj`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then(() => {
                            thisIns.$printSuccess(`${thisIns.$t('general.paper_submited_successfully')}\n`)
                            setTimeout(() => {
                                this.$router.go(-1)
                            }, 500)

                            thisIns.showLoader = false

                        })
                        .catch(error => {
                            thisIns.$printError(`${thisIns.$t('general.paper_submit_error')}\n${error.message}`)
                            thisIns.showLoader = false
                        })
                }
            },
            getPodrocjeNazivFromArray(podrocja) {
                if (!podrocja) return ''
                return podrocja.map(p => { return p.Name }).join(', ')
            },
            joinNames(arrayOfNames) {
                if (!arrayOfNames) return ''
                return arrayOfNames.map(p => { return `${p.Firstname} ${p.Lastname}` }).join(', ')
            },
            getFullnameFromMentor(peopleArray) {
                if (!peopleArray) return ''
                return peopleArray.map(p => { return `${p.ProfessionalTitle} ${p.Firstname} ${p.Lastname}` }).join(', ')
            },
            loadData() {
                const thisIns = this
                thisIns.showLoader = true

                const url = `/v1/admin/naloga/${thisIns.id}`

                thisIns.$http.get(url)
                    .then(res => {
                        thisIns.paper = res.data
                        localStorage.setItem('schoolId', res.data.SchoolID)
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            onDeleteButtonPressed() {
                this.$bvModal.show('confirm-deletion-modal')
            },
            onAcceptDeletion() {
                this.deletePaper(this.id)
            },
            onRejectDeletion() {
                this.$bvModal.hide('confirm-deletion-modal')
            },
            deletePaper() {
                const thisIns = this
                this.$bvModal.hide('confirm-deletion-modal')
                thisIns.showLoader = true

                const url = `/v1/admin/naloga/${this.id}`

                thisIns.$http.delete(url)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.paper_successfully_deleted')}\n`)
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 500)

                        thisIns.showLoader = false

                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.delete_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
         
        },
        created() {
            this.id = this.$route.params.id
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style scoped>
.summary {
  font-size: 16px;
}
</style>
